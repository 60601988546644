<template>
  <div class="w100 mr-50" v-for="(el, ind) in props.signaturePositionsData" :key="ind">
    <!-- {{ el }} -->
    <div class="dp-f ai-c mb-12">
      <div class="fs-0">签署方：
        <template v-if="el.signatoryType == 2">
          <template v-if="el.type==0">
            <template v-if="el.signingRequirements === 0">
              企业签章
            </template>
            <template v-else>
              法定代表人签章
            </template>
          </template>
          <template v-else-if="el.type==1">
            个人签名
          </template>
          <template v-else-if="el.type==2">
            日期位置
          </template>
        </template>
        <template v-else-if="el.signerType == 0">
          {{ el.signName }}-{{ el.signPhone }}
        </template>
        <template v-else-if="el.signerType == 1">
          {{ el.companyName }}
          <template v-if="el.signingRequirements === 0">
            -企业签章
          </template>
          <template v-else-if="el.signingRequirements === 1">
            -经办人签名
          </template>
          <template v-else-if="el.signingRequirements === 2">
            -法定代表人签章
          </template>
          <template v-else>
            -{{ el.signName }}
          </template>
        </template>
      </div>
      <!-- <el-select v-model="el.signName" class="m-2 selectReset w100" placeholder="请选择签署方">
                <el-option v-for="item in props.options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select> -->
    </div>
    <!-- signName: item.signName||'',//签署方名称
		signPhone: item.signPhone||'',//签署方手机号
		companyName:item.companyName||'',//公司名称
		signingRequirements:signingRequirements,//签署要求 0企业签章 1经办人签名 2法定代表人签章
		signerType:signerType,//类型 0:个人 1:公司 -->
    <template v-for="(item, index) in el.sealPositions" :key="index">
      <div class="dp-f jc-sb pl-6 pr-6 bg-f1 w100 mb-12">
        <el-input class="inputbgnone" v-model="item.name" maxlength="10" />
        <div class="dp-f">
          <el-button type="text" style="color:#1677FF" @click="emit('getview', el, item)">查看</el-button>
          <el-button type="text" class="ml-5" style="color:#666666" @click="emit('reset', el, item)">重置</el-button>
          <el-button type="text" class="ml-5" style="color:#FF0000" @click="emit('delete', el, index)">删除</el-button>
        </div>
      </div>
    </template>
    <div class="addbox dp-fc cu-p mb-12" @click="emit('add', el)">
      <el-image :src="require('@/assets/img/initiateContract/add2.png')" class="w-16 h-16 mr-8" />
      添加位置
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps } from "vue";
const props = defineProps({
  signaturePositionsData: {
    //当前合同接收方数据
    type: Array,
    default() {
      return [];
    },
  },
  options: {
    //所有接收方数据
    type: Array,
    default() {
      return [{
        label: '夏天合同',
        value: '夏天合同',
      }];
    },
  }
});
const emit = defineEmits([
  'getview', 'reset', 'delete', 'add'
]);
</script>

<style lang="scss" scoped>
@import "@/styles/color/value.scss";

.selectReset {
  ::v-deep .el-input__wrapper {
    box-shadow: 0 0 0 1px $active-theme inset !important;
  }

  ::v-deep .el-input.is-focus .el-input__wrapper {
    box-shadow: 0 0 0 1px $active-theme inset !important;
  }
}

.inputbgnone {
  ::v-deep .el-input__wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}

.addbox {
  width: 100%;
  height: 32px;
  border: 1px dashed #E2E2E2;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.fs-0 {
  flex-shrink: 0;
}
</style>