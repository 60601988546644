<template>
	<mainPop popWidth=1112px popClass='mainPopClass' @confirm='saveSignInfo' @cancel="dialogVisible = false"
		:openhint=dialogVisible titleText=签署设置 v-model:dialogloading="state.loading">
		<template v-slot:el-popBody>
			<div class="dp-f mainSignbox">
				<div class="w100 settingleft">
					<div class="topSelect dp-f jc-sb ai-c">
						<div class="dp-f">
							<oabutton title=删除选择框 CSStype=2 width=110 height=32
								@buttonclick="state.nowContract.signaturePositions[state.nowSignIndex.ind].sealPositions.splice(state.nowSignIndex.ind2, 1), state.nowSign = null" v-show="state.nowSign"></oabutton>
						</div>
						<div>
							文件选择：
							<el-select size="mini" v-model="state.nowContract" @change="contractChange" value-key="id">
								<el-option v-for="(item, index) in state.contractOptions" :key="item.id" :label="item.fileChname"
									:value="item" />
							</el-select>
						</div>
					</div>
					<!-- {{ state.nowContract.signaturePositions }} -->
					<div class="imgbox mb-10 pt-r">
						<el-image :src="state.nowContract.pages[state.nowpage - 1].fileUrl" class="fileimg" ref="fileimgRef"></el-image>
						<!-- <img :src="state.nowContract.pages[state.nowpage - 1].fileUrl" v-if="state.nowContract.pages[state.nowpage - 1].fileUrl" class="fileimg" ref="fileimgRef" /> -->
						<template v-for="(el, ind) in state.nowContract.signaturePositions" :key="ind">
							<template v-for="(item, index) in el.sealPositions" :key="index">
								<VueDragResize v-if="item.pageIndex == state.nowpage" class="sealbox" :id="ind + index"
									:parentW='state.parentW' :parentH='state.parentH' :x='item.left' :y='item.top' :parentLimitation="true"
									:isActive="true" :isResizable="false" :aspectRatio="true" :w="item.width" :h="item.height"
									@dragstop="dragstop($event, item)">
									<span class="w100 h100 dp-fc cu-p"
										@click="state.nowSign == item ? state.nowSign = '' : state.nowSign = item, state.nowSignIndex.ind = ind, state.nowSignIndex.ind2 = index">{{ item.name }}</span>
									<!-- --{{ item.signatureX }}--{{ item.signatureY }} -->
									<!-- <div class="applications dp-f jc-c" v-if="state.nowSign == item">
										<div class="operation" @click="unref(batchApplicationRef).show(state.contractOptions)">批量应用</div>
									</div> -->
								</VueDragResize>
							</template>
						</template>
					</div>
					<getpages class="mb-10" @Emit="getpageEmit" :maxpages="state.maxpages" ref="getpagesRef"></getpages>
				</div>
				<div class="w-485 fs-0 Sitebox autobox">
					<!-- {{ props.signatorysData }} -->
					<!-- {{ state.nowContract }} -->
					<el-form :label-width="130">
						<template v-for="(el, ind) in state.ruleForms" :key="ind">
							<el-form-item :label="el.title + ':'" v-if="(el.key == 'isSetSignature'&&signSiteAll.length > 0)||(el.key == 'isSetAutograph'&&signatureSiteAll.length > 0)||(el.key == 'isSetDate'&&dateSiteAll.length > 0)||el.key == 'isSetPagInSeal'">
								<el-radio-group v-model="state.nowContract[el.key]">
									<el-radio :label="item.value" v-for="(item, index) in el.optons" :key="index">{{ item.label}}</el-radio>
								</el-radio-group>
								<template v-if="state.nowContract[el.key] == '1'">
									<template v-if="el.key == 'isSetSignature'">
										<signSettingItem v-model:signaturePositionsData="signSiteAll"
											@add="signSettingItemAdd($event, el.key)" @getview="signSettingItemGetview"
											@reset="signSettingItemReset" @delete="signSettingItemDelete">
										</signSettingItem>
									</template>
									<template v-else-if="el.key == 'isSetAutograph'">
										<signSettingItem v-model:signaturePositionsData="signatureSiteAll"
											@add="signSettingItemAdd($event, el.key)" @getview="signSettingItemGetview"
											@reset="signSettingItemReset" @delete="signSettingItemDelete">
										</signSettingItem>
									</template>
									<template v-else-if="el.key == 'isSetDate'">
										<signSettingItem v-model:signaturePositionsData="dateSiteAll"
											@add="signSettingItemAdd($event, el.key)" @getview="signSettingItemGetview"
											@reset="signSettingItemReset" @delete="signSettingItemDelete">
										</signSettingItem>
									</template>
								</template>
							</el-form-item>
						</template>
						<div align="center">
							<el-button  type="primary" plain size="large" @click="clickAddSetting">确认设置</el-button>
							<el-button  type="warning" plain size="large" @click="cancelSetting">重置</el-button>
						</div>
					</el-form>
				</div>
			</div>
		</template>
	</mainPop>
	<batchApplication ref="batchApplicationRef"></batchApplication>
</template>
<script setup>
import { ref, unref, reactive, defineEmits, computed, nextTick, getCurrentInstance } from 'vue'
import mainPop from "@/components/dialog/mainPop.vue";
import { getqianDetail } from '@/utils/server/getcode'
import getpages from "@/components/pagination/getpages.vue"
import signSettingItem from "./signSettingItem.vue"
import VueDragResize from "vue-drag-resize/src"; //拖动组件
import batchApplication from "./batchApplication.vue"//批量应用组件
import { httpToken } from "@/utils/request";
import { router_push_name } from "@/utils/server/router.js"
import { handleMessage } from "@/utils/server/confirm.js"
import { mul, div } from "@/utils/server/format.js"
import {configu} from '@/utils/config'
import qs from 'qs'
const instance = getCurrentInstance();
const fileimgRef = ref()//文件图片ref
const getpagesRef = ref()//分页组件ref
const dialogVisible = ref(false)//弹框显示
const batchApplicationRef = ref()//批量导入弹框
const state = reactive({
	loading: false,//loading显示
	contractInfo: '',//当前合同信息
	parentW: 0,//父组件宽度
	parentH: 0,//父组件高度
	nowSign: "",//当前选中印章信息
	nowSignIndex: {
		ind: 0,//签署方下标
		ind2: 0,//印章下标
	},//当前选中印章下标 删除使用
	nowContract: {},//当前选中合同文件数据
	contractOptions: [],//当前选中所有合同文件信息
	signaturePositionsModel: [],//当前接收方模板
	nowpage: 1,//当前页码数
	maxpages: 1,//最大页码数
	ruleForms: [
		{ title: '签章位置', key: 'isSetSignature', optons: [{ value: 0, label: '不指定位置' }, { value: 1, label: '指定位置' }], signis: true },
		{ title: '签名位置', key: 'isSetAutograph', optons: [{ value: 0, label: '不指定位置' }, { value: 1, label: '指定位置' }], signis: true },
		// { title: '日期位置', key: 'isSetDate', optons: [{ value: 0, label: '不指定位置' }, { value: 1, label: '指定位置' }], signis: true },
		// { title: '骑缝章', key: 'isSetPagInSeal', optons: [{ value: 0, label: '不需要' }, { value: 1, label: '需要' }], signis: false },
	],
})

function clickAddSetting() {
	// console.log("add setting click")
	if ( !checkKey(state.nowContract) ) {
		// console.log("return false")
		return
	}
	let nxt = -1
	for(let i = 0; i < state.contractOptions.length; i++) {
		if (state.contractOptions[i].id == state.nowContract.id ) {
			state.contractOptions[i].isSetting = true
			continue
		}
		if (!state.contractOptions[i].isSetting && nxt == -1)
			nxt = i
	}
	// console.log("nxt:", nxt)
	if (nxt >= 0) {
		state.nowContract = state.contractOptions[nxt]
		changeNowContract(state.nowContract)
	} else {
		handleMessage("所有文档都已确认签署设置，可点击保存按钮继续", 'success')
	}
}
function cancelSetting() {
	changeNowContract(state.nowContract, 2)
	state.nowContract.isSetSignature = 0
	state.nowContract.isSetAutograph = 0
	state.nowContract.isSetDate = 0

	// console.log("cancel setting click")
}
// 选择文件Change方法
const contractChange = ((val) => {
	changeNowContract(val)
})
// 拖拽结束事件
const dragstop = ((value, item) => {
	item.top = value.top
	item.left = value.left
	item.signatureY = mul(div(value.top + value.height, state.parentH), 100).toFixed(3)
	item.signatureX = mul(div(value.left, state.parentW), 100).toFixed(3)
	if (item.signatureY > 100) {
		item.signatureY = 100
	}
	console.log("value, item", value, item)
})
// 重新设置父组件宽高
const resetParentWH = (() => {
	nextTick(() => {
		setTimeout(() => {
			state.parentW = fileimgRef.value.$el.offsetWidth
			state.parentH = fileimgRef.value.$el.offsetHeight
		}, 200);
	})
})
const emit = defineEmits(['Emit'])
// 改变当前选中合同文件
const changeNowContract = (data, type = 1) => {
	state.nowpage = 1
	// state.nowpag
	nextTick(() => {
		unref(getpagesRef).setPage(1)
	})
	state.nowContract = data
	state.maxpages = data.pages.length
	resetParentWH()
	// 如果没有添加过印章信息 将模板印章信息赋值
	if (state.nowContract.signaturePositions.length == 0 || type == 2) {
		state.nowContract.signaturePositions = JSON.parse(JSON.stringify(state.signaturePositionsModel))
		state.nowContract.signaturePositions.forEach(item => {
			item.fileId = data.id
		})
	}
}
// 分页组件返回新页码
const getpageEmit = ((value) => {
	state.nowpage = value
})
// 签署设置执行生成
const show = ((signatorys, filesContracts, contractInfo) => {
	state.contractInfo = contractInfo
	dialogVisible.value = true
	state.contractOptions = filesContracts
	for (let i = 0; i < filesContracts.length; i++ ) {
		state.contractOptions[i].isSetting = false
	}
	state.signaturePositionsModel = []
	// 根据传入的签署方数据生成基础的印章模板
	signatorys.forEach((item,ind) => {
		console.log('当前项签署方数据', item)
		// type 0 通讯录添加 1手填 2批量导入 3模板
		if(item.type==3){
			console.log('模板数据', item)
			// signerType 0:个人 1:公司
			if (item.signerType == 0) {
				// setRecipientsModel(0, item.contractSignPersonList[0], undefined, 0)
				setRecipientsModeltem(1, undefined, 0,item.type)
				// setRecipientsModeltem(2, undefined, 0,item.type)
			}else if (item.signerType == 1) {
				setRecipientsModeltem(0, 0, 1,item.type)
				setRecipientsModeltem(1, 1, 1,item.type)
				// setRecipientsModeltem(2, 2, 1,item.type)
			}
		}else{
			// signerType 0:个人 1:公司
			if (item.signerType == 0) {
				// setRecipientsModel(0, item.contractSignPersonList[0], undefined, 0)
				setRecipientsModel(1, item.contractSignPersonList[0], undefined, 0,item.type)
				// setRecipientsModel(2, item.contractSignPersonList[0], undefined, 0,item.type)
			} else if (item.signerType == 1) {
				// 0 企业签章 1经办人签名 2法定代表人签章
				if (item.requirements.includes('0')) {
					setRecipientsModel(0, item.contractSignPersonList[0], 0, 1,item.type)
				}
				if (item.requirements.includes('1')) {
					setRecipientsModel(1, item.contractSignPersonList[0], 1, 1,item.type)
				}
				if (item.requirements.includes('2')) {
					setRecipientsModel(0, item.contractSignPersonList[0], 2, 1,item.type)
				}
				// setRecipientsModel(2, item.contractSignPersonList[0], undefined, 1,item.type)
			}
		}
	})
	changeNowContract(filesContracts[0], 2)
	instance.proxy.$forceUpdate();//强制刷新
})
// 模板签署位置数据
const setRecipientsModeltem = (type, signingRequirements = '', signerType,signatoryType) => {
	let data={
		type: type,// 0签章 1签名 2日期位置 3 骑缝章
		signName: '模板签署方',//签署方名称
		signPhone: '模板手机号',//签署方手机号
		signingRequirements: signingRequirements,//签署要求 0企业签章 1经办人签名 2法定代表人签章
		signerType: signerType,//类型 0:个人 1:公司
		signatoryType:signatoryType,//2为批量导入 展示文字修改
		// fileId 文件id
		sealPositions: [],
	}
	if(signerType==1){
		data.companyName='模板公司名称'//公司名称
	}
	state.signaturePositionsModel.push(data)
}
// 模板签署位置数据
const setRecipientsModel = (type, item, signingRequirements = '', signerType,signatoryType) => {
	state.signaturePositionsModel.push({
		type: type,// 0签章 1签名 2日期位置 3 骑缝章
		signName: item.signerName,//签署方名称
		signPhone: item.signerPhone,//签署方手机号
		companyName: item.companyName || null,//公司名称
		signingRequirements: signingRequirements,//签署要求 0企业签章 1经办人签名 2法定代表人签章
		signerType: signerType,//类型 0:个人 1:公司
		signatoryType:signatoryType,//2为批量导入 展示文字修改
		// fileId 文件id
		sealPositions: [],
	})
}
// 删除印章
const signSettingItemDelete = ((el, ind) => {
	el.sealPositions.splice(ind, 1)
})
// 印章位置重置
const signSettingItemReset = ((el, item) => {
	item.left = 0
	item.signatureX = 0
	setTimeout(() => {
		item.top = 0
		item.signatureY = 0
	}, 10);
})
//查看印章位置 (跳转至改印章页数)
const signSettingItemGetview = ((el, item) => {
	unref(getpagesRef).setPage(item.pageIndex)
})
// 增加印章位置
const signSettingItemAdd = ((el, key) => {
	let sealName
	let height = configu.entSealHeight 
	if (key == 'isSetSignature') {
		if(el.signatoryType==1){
			if(el.signingRequirements !== 0){
				height = configu.legalSealHeight
			}
		}		
		sealName=el.companyName + '-' + getqianDetail(el.signingRequirements)
		const rheight = (height/800*600).toFixed(3)
		const sy = (Number(rheight)/state.parentH*100).toFixed(3)
		console.log("h, H, sy", rheight, state.parentH, sy)
		el.sealPositions.push({ name: sealName, signatureY: sy, signatureX: 0, top: 0, left: 0, width: rheight, height: rheight, pageIndex: state.nowpage })
	} else if (key == 'isSetAutograph') {
		if(el.signatoryType==2){
			sealName='个人签名'
		}else{
			sealName=el.companyName ? el.companyName + '-' + getqianDetail(el.signingRequirements) : el.signName
		}
		const height = (configu.personSignHeight/800*600).toFixed(3)
		const sy = mul(div(Number(height), state.parentH), 100).toFixed(3)
		console.log("h, H, sy", height, state.parentH, sy)
		el.sealPositions.push({ name: sealName, signatureY: sy, signatureX: 0, top: 0, left: 0, width: (configu.personSignWidth/800*600).toFixed(3), height: height, pageIndex: state.nowpage })
	} else if (key == 'isSetDate') {
		if(el.signatoryType==2){
			sealName='日期位置'
		}else{
			sealName=el.companyName ? el.companyName + '日期位置' : el.signName + '日期位置'
		}
		el.sealPositions.push({ name:sealName , signatureY: 0, signatureX: 0, top: 0, left: 0, width: (176/800*600).toFixed(0), height: (38/800*600).toFixed(0), pageIndex: state.nowpage })
	}
})
const checkKey = (contractItem) => {
	for (let i = 0; i < contractItem.signaturePositions.length; i++) {
		if (contractItem.isSetSignature == 1) {
			if (contractItem.signaturePositions[i].type == 0 && contractItem.signaturePositions[i].sealPositions.length == 0) {
				handleMessage('请先完成签章位置指定')
				return false
			}
		}
		if (contractItem.isSetAutograph == 1) {
			if (contractItem.signaturePositions[i].type == 1 && contractItem.signaturePositions[i].sealPositions.length == 0) {
				handleMessage('请先完成签名位置指定')
				return false
			}
		}
		if (contractItem.isSetDate == 1) {
			if (contractItem.signaturePositions[i].type == 2 && contractItem.signaturePositions[i].sealPositions.length == 0) {
				handleMessage('请先完成日期位置指定')
				return false
			}
		}
	}
	return true
}
// 设置签章位置
const saveSignInfo = async () => {
	for (let i = 0; i < state.contractOptions.length; i++) {
		// if (!checkKey(state.contractOptions[i])) {
		// 	return
		// }
		if (!state.contractOptions[i].isSetting) {
			handleMessage("文件" + state.contractOptions[i].fileChname + "未确认签署设置")
			return
		}
	}
	// 新增合同文件关联的签章位置和水印表
	let json = {
		files: JSON.stringify(state.contractOptions),
		contractId: state.contractInfo.id,//合同id
	}
	state.loading = true
	await httpToken({
		method: "post",
		url: '/initiate-contract/contract/addCPWList',
		data: qs.stringify(json)
	}).then((res) => {
		state.loading = false
		handleMessage('成功发起合同', 'success')
		router_push_name('operation_sign', undefined, undefined, true)
	}).catch(() => {
		state.loading = false
	})
}
// 统计某个分类签署方数据
const getStatistics = ((data, type) => {
	return data.filter(item => {
		return item.type == type
	})
})
const signSiteAll = computed(() => {//所有签章接收方数据
	return getStatistics(state.nowContract.signaturePositions, 0);
});
const signatureSiteAll = computed(() => {//所有签名接收方数据
	return getStatistics(state.nowContract.signaturePositions, 1);
});
const dateSiteAll = computed(() => {//所有日期接收方数据
	return getStatistics(state.nowContract.signaturePositions, 2);
});
defineExpose({
	show
});
</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";

.applications {
	.operation {
		margin-top: 10px;
		cursor: pointer;
		padding: 4px 8px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		background: #1677FF;
	}
}

.mainSignbox {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	display: auto;

	.settingleft {
		width: 100%;
		padding-left: 5px;
		padding-right: 5px;
		border-right: 1px solid #e7e7e7;
		overflow: auto;
		height: 100%;

		.topSelect {
			::v-deep .el-select {
				width: 200px;

				.el-input__wrapper {
					border-radius: 0;
					box-shadow: $active-theme 0 0 0 1px !important;
				}
			}
			height: 60px;
		}
		.fs-0 {
			flex-shrink: 0;
		}
		.imgbox {
			width: 100%;
			display: flex;
			justify-content: center;
			box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.11);
			min-height: 400px;
			max-height: 500px;
			overflow: auto;
			position: relative;
			.fileimg {
				width: 100%;
				height: 100%;
			}
		}
	}
	.Sitebox {
		max-height: 602px;
		padding-top: 10px;
	}
}
::v-deep .el-dialog__header {
	background-color: $active-theme !important;
}



::v-deep .sealbox {
	border: 1px solid #FF0000 !important;
	background: rgba(255, 0, 0, 0.1);
}

.vdr.active:before {
	display: none;
}</style>