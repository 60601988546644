<template>
  <el-dialog v-model="dialogVisible" :close-on-click-modal="false" width="560px">
    <template #header="{ close, titleId, titleClass }">
      <div class="headbox">批量应用 已选择({{}})</div>
    </template>
    <!-- {{ state.Notice }} -->
    <el-table :data="state.Notice" class="mb-20"
        :header-cell-style="{ background: state.Noticebackground, color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
        row-key="id" @selection-change="selectionChange">
        <el-table-column :align="'center'" type="selection" width="55" />
        <el-table-column :align="'center'" type="index" label="序号" width="80" />
        <el-table-column :align="'center'" prop="name" label="文件名称" />
        <el-table-column :align="'center'" prop="name2" label="签署方名称"/>
    </el-table>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width="110" height='42' title="确定" CSStype=2 @buttonclick="dialogVisible = false"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width="110" height='42' title="取消" CSStype=1 @buttonclick="dialogVisible = false"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref,reactive, defineEmits } from 'vue'
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const state = reactive({
  Noticebackground:'rgba(234, 234, 234, 1)',
  Notice:[],//表格
})
const show = ((data) => {
  dialogloading.value = false
  dialogVisible.value = true
  state.Notice=data
})
defineExpose({
  show,
});
</script>
<style lang="scss" scoped >
.headbox{
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.batchbox {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}</style>