<template>
  <div class="dp-f jc-c ai-c">
    <div class="mr-26">
      当前第{{ state.nowpage }}页
    </div>
    <img src="@/assets/img/general/left.png" class="cu-p mr-14" @click="state.nowpage--">
    <el-input v-model="state.nowpage" class="mr-16"
      @input="state.nowpage = state.nowpage.replace(config.limit.number_rules, '')" />
    <div class="mr-16">/</div>
    <div class="mr-13">{{ props.maxpages }}</div>
    <img src="@/assets/img/general/left.png" class="cu-p tsy-180" @click="state.nowpage++">
  </div>
</template>
<script setup>
import { ref, reactive, watch, defineEmits, defineExpose } from 'vue'
const emit = defineEmits(['Emit'])
let props = defineProps({
  maxpages: {
    type: Number,
    default: 1
  },//最大页码数
});
const state = reactive({
  nowpage: 1,//当前页码数
})
watch(() => state.nowpage,
  (value, oldValue) => {
    if (value > props.maxpages || value < 1) {
      state.nowpage = 1
    } else {
      emit('Emit', state.nowpage)
    }
  },
  { immediate: true }//初始化时立即执行回调函数
)
const setPage = ((ind) => {
  state.nowpage = ind
})
defineExpose({
  setPage
});
</script>
<style lang="scss" scoped>
.el-input {
  width: 48px;
  height: auto !important;
}

.tsy-180 {
  transform: rotateY(180deg)
}
</style>